import React, { useEffect, useState } from 'react';
//import './assets/css/body.css'; // Import CSS from the theme
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { getCleanedDomainName } from './urlFetch';
import {Helmet} from "react-helmet";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function Article() {
  // Domain details Fetch
  const [domainName, setDomainName] = useState('');

  // Fetching the domain name
  useEffect(() => {
    setDomainName(getCleanedDomainName());
  }, []);

  console.log('Cleaned Domain Name:', domainName);

  const [microData, setMicroData] = useState({
    id: '',
    name: '',
    domain: '',
    p1: '',
    p2: '',
    p3: '',
    place: '',
  });
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_data/${domainName}`;

  useEffect(() => {
    if(domainName){
      console.log('API URL:', apiUrl);

      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          console.log('API Response:', data);
          setMicroData(data[0]); // Assuming data is an array with a single item
        })
        .catch(error => console.error('API Error:', error));
    }
  }, [domainName, apiUrl]);

  //console.log('Name:', microData.name);
  //console.log('Domain:', microData.domain);
  console.log('P1:', microData.p1);
  console.log('P2:', microData.p2);
  console.log('P3:', microData.p3);


  const [swiperData, setSwiperData] = useState([]);
  
  useEffect(() => {
    if(microData.id){
      const microDataUrl = `${process.env.REACT_APP_API_ENDPOINT}home_widget_ids?cat=temple_slides&id=${microData.id}`;
      console.log('microDataUrl:', microDataUrl);
      // Replace with your API URL
      fetch(microDataUrl)
        .then((response) => response.json())
        .then((data) => setSwiperData(data))
        .catch((error) => console.error('Error fetching data:', error));
    }
  }, [microData.id]);


  function stripHtmlTags(html) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  }

  const { id, title } = useParams();

  // Articles fetch starts
  const [p1, setP1] = useState({
    title: '',
    content: '',
    enquiry: '',
    content_type: '',
  });
  

  useEffect(() => {
    if(id){
      const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}temple_articles/${id}`;

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          const firstItem = data[0];

          if (firstItem && firstItem.title && firstItem.content) {
            setP1({
              title: firstItem.title,
              content: firstItem.content,
              enquiry: firstItem.enquiry,
              content_type: firstItem.content_type,
            });
          } else {
            console.error('Temple article data is incomplete.');
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
    }
  }, [id]);

  let p1Refined = p1.title ? p1.title.toLowerCase().replace(/\s+/g, '-') : '';
  let p1URL = '';
  if(domainName == 'shirdisaitemple'){
    p1URL = `https://www.${domainName}.com/shirdi-article/id/${id}/${p1Refined}/`;
  }
  else{
    p1URL = `https://www.${domainName}.com/article/id/${id}/${p1Refined}/`;
  }

  const [tourPackages, setTourPackages] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);

  useEffect(() => {
    if(microData.id){
      const tourPackagesURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_tour_listings/${microData.id}`;
      console.log("tourPackagesURL: ", tourPackagesURL);
      fetch(tourPackagesURL)
      .then(response => response.json())
      .then(data => setTourPackages(data))
      .catch(error => console.error(error));

    // Fetch gallery images
    const galleryImagesURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_temple_galleries/${microData.id}`;
    fetch(galleryImagesURL)
      .then(response => response.json())
      .then(data => setGalleryImages(data))
      .catch(error => console.error(error));

    }
  }, [microData.id]);

  
  const [articleListings, setArticleListings] = useState([]);

  useEffect(() => {
    if(microData.id){
      const articleListingsURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_article_listings/${microData.id}?all=1`;
      console.log("articleListingsURL: ", articleListingsURL);
      fetch(articleListingsURL)
      .then(response => response.json())
      .then(data => setArticleListings(data))
      .catch(error => console.error(error));
    }
  }, [microData.id]);


  // email subscription
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [email, setEmail] = useState({
    email: ''});
  
  const handleSubscriptionSubmit = (e) => {
    e.preventDefault();
    // Check if both input fields are empty
    if (email && !e.target.subscriber_user.value) {
      // If only the first input is filled, submit the data
      const formDataSubscribe = new FormData(e.target);
      console.log("formData", email);
      fetch(`${process.env.REACT_APP_API_ENDPOINT}email_subscription`, {
        method: 'POST',
        body: email,
      })
        .then((response) => response.json())
        .then((data) => {
          // Check the response from your API for success
          if (data.success) {
            setIsFormSubmitted(true);
          }
        })
        .catch((error) => {
          console.error('Error submitting the form:', error);
        });
    }
    // You can handle the case where both fields are filled and ignore the submission here
  };


  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    mobileNumber: '',
    noOfPersons: '',
    hotelType: '',
    startDate: '',
    endDate: '',
    message: '',
    graduationDay: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEnquirySubmit = (e) => {
    e.preventDefault();

    if (formData.graduationDay !== '') {
      // A value is present in the honeypot field, likely a bot
      return;
    }

    if (
      formData.fullName.trim() === '' ||
      formData.email.trim() === '' ||
      formData.mobileNumber.trim() === '' ||
      formData.noOfPersons.trim() === ''
    ) {
      alert('Please fill in all required fields.');
    } else {
      // Prepare the form data for submission
      const data = {
        fullName: formData.fullName,
        email: formData.email,
        mobileNumber: formData.mobileNumber,
        noOfPersons: formData.noOfPersons,
        hotelType: formData.hotelType,
        startDate: formData.startDate,
        endDate: formData.endDate,
        message: formData.message,
        website: domainName,
        page_title: p1.title
      };

      console.log("enquiry data: ", data);

      // Replace 'your-api-endpoint' with your actual API endpoint
      fetch(`${process.env.REACT_APP_API_ENDPOINT}enquiry_submission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            // Handle a successful response here
            setIsSubmitted(true);
          } else {
            // Handle errors here
            console.error('Form submission failed');
          }
        })
        .catch((error) => {
          console.error('Error submitting the form:', error);
        });
    }
  };

  console.log("p1 content type: ",p1.content_type);

  
  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{p1.title}</title>
    </Helmet>
    {/* Slider section */}
    <section className="z-5">
      <div className="swiper-wrapper">
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          loop={true}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {swiperData.map((item) => (
            <SwiperSlide key={item.id}>
              <img style={{width:'100%'}} src={`https://blessingsonthenet.com/img/slideshow/${item.image}`} alt={`Slide ${item.id}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>

    {/* The 3 articles section */}
    <section class="section-bg pt-30 pb-30">
      <div class="section-bg__item -mx-20"></div>

      <div data-anim-wrap class="container">
        <div class="row y-gap-30">
          <div class="col-xl-8">
            <div id="overview" class="col-12">
                <h1 class="sectionTitle__title text-26 mb-30">{p1.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: p1.content }} />
                {/*<a href="#" class="d-block text-14 text-blue-1 fw-500 underline mt-10">Show More</a>*/}
            </div>
          </div>

          <div class="col-xl-4">      

              <div class="ml-50 lg:ml-0">

                  <div class="px-30 pt-10 pb-20 border-light rounded-4 shadow-4" id="book-now">
                      <div class="d-flex items-center justify-between">
                          <div class="">
                          <span class="text-20 fw-500">Book Now</span>
                          </div>
                      </div>
                      
                      <div className="text-center">
                          <br />
                          <h6 style={{color:'#FF0000'}}>WhatsApp for Immediate Response and Customization</h6>
                          <br />
                          <a href="https://wa.me/919892002006">+91 9892 002 006</a><br />
                          <a href="https://wa.me/919224444455">+91 9224 444 455</a>
                          <br /><br />
                          <h6 style={{color:'#FF0000'}}>Send Us Email</h6>
                          <a href="mailto:travel@blessingsonthenet.com">travel@blessingsonthenet.com</a><br /><br />
                          {/*
                          <a href={`https://www.blessingsonthenet.com/hotel/${refined_url}/${id}`} class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                              Book Now
                              <div class="icon-arrow-top-right ml-15"></div>
                          </a><br />
                          */}
                      </div>

                  </div>

              </div>
          </div>
        </div>
        
      </div>
    </section>

    {/* Enquiry Form */}
    {p1.enquiry === "1" ? (
    <section class="layout-pt-lg layout-pb-md mt-50">
      <div data-anim-wrap="" class="container animated">
        <div data-anim-child="slide-up delay-1" class="row justify-center text-center is-in-view">
          <div class="col-auto">
            <div class="sectionTitle -md">
              <h2 class="sectionTitle__title">Enquire with Us</h2>
              <p class=" sectionTitle__text mt-5 sm:mt-0">Have any questions? feel free to get in touch with us and we will answer all your questions.</p>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div style={{backgroundColor: '#13357B', color: '#fff',}} class="mt-50 px-40 pt-30 pb-30 lg:px-30 lg:py-30 md:px-24 md:py-24 rounded-4 shadow-4">
                <div class="card-body">
                  <h4 style={{color: '#fff',}} class="card-title">WhatsApp Us</h4><br />
                  <p style={{color: '#fff',}} class="card-text">Request a quote, or just chat about your next vacation. We're always happy to help!</p><br />
                  <a target="_blank" href="https://wa.me/919892002006"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9892 002 006</h5></a>
                  <a target="_blank" href="https://wa.me/919224444455"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9224 444 455</h5></a>
                </div>
              </div>
              <div style={{backgroundColor: '#ff894c', color:'#051036'}} class="mt-50 px-40 pt-40 pb-50 lg:px-30 lg:py-30 md:px-24 md:py-24 rounded-4 shadow-4">
                <div class="card-body">
                  <h4 style={{color:'#051036'}} class="card-title">Write to Us</h4><br />
                  <p style={{color:'#051036'}} class="card-text">Do you like to customize this hotel stay even more or be it an enquiry, feedback or a simple suggestion, write to us.</p><br />
                  <a target="_blank" href="mailto:travel@blessingsonthenet.com"><h5 style={{color:'#051036'}} class="card-text"><img src="/assets/img/icons/email.png" style={{width:'25px'}} /> travel@blessingsonthenet.com</h5></a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
            <div className="mt-50 px-40 pt-35 pb-40 lg:px-30 lg:py-30 md:px-24 md:py-24 bg-white rounded-4 shadow-4">
              <div className="text-22 fw-500">Enter your journey details for travel enquiry.</div>
                <div className="row y-gap-15 pt-20">
                  <div className="col-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Full Name*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Email Address*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Mobile Number*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="text"
                        name="noOfPersons"
                        value={formData.noOfPersons}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">No. of Person*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input" style={{minHeight:'70px'}}>
                    <label className="lh-1 text-16 text-light-1" style={{top:'12px'}}>Hotel Type</label>
                      <select
                        style={{
                          border: '1px solid #DDDDDD',
                          borderRadius:'4px',
                          padding: '0 15px',
                          paddingTop:'25px'}}
                        name="hotelType"
                        value={formData.hotelType}
                        onChange={handleChange}
                      >
                      <option value="3 Star">3 Star</option>
                      <option value="4 Star">4 Star</option>
                      <option value="5 Star">5 Star</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="startDate"
                        value={formData.startDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>Start Date</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="endDate"
                        value={formData.endDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>End Date</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input">
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        rows="2"
                      ></textarea>
                      <label className="lh-1 text-16 text-light-1">Message (If Any)</label>
                    </div>
                  </div>
                  <div style={{ display: 'none' }}>
                    <input
                      type="text"
                      name="graduationDay"
                      value={formData.graduationDay}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-auto">
                    <button
                      onClick={handleEnquirySubmit}
                      className="button px-24 h-50 -dark-1 bg-blue-1 text-white"
                    >
                      Send a Message
                      <div className="icon-arrow-top-right ml-15"></div>
                    </button>
                  </div>
                  <div className="col-12">
                    {isSubmitted && <p style={{color:'green'}}>Thanks for submitting your details, we will get back to you shortly!</p>}
                  </div>
                </div>
            </div>
            </div>
          </div>
        </div>
        
      </div>
    </section>
    ) : ''}

    {/* Holidays Section */}
    <section className="layout-pt-lg layout-pb-md">
      <div className="container">
        <div data-anim="slide-up delay-1" className="row y-gap-20 justify-between items-end is-in-view">
          <div className="col-auto">
            <div className="sectionTitle -md">
              <h2 className="sectionTitle__title">Related Tour Packages</h2>
            </div>
          </div>

          <div className="col-auto md:d-none">

            <a href="https://www.blessingsonthenet.com/travels/travel/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
              View All Holidays <div className="icon-arrow-top-right ml-15"></div>
            </a>

          </div>
        </div>

        <div className="relative pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-autoheight swiper-watch-progress swiper-backface-hidden is-in-view">
          <div className="swiper-wrapper">
            <Swiper
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              loop={true}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className="mySwiper"
              breakpoints={{
                // When window width is <= 767px (typically mobile phones), show 2 slides
                767: {
                  slidesPerView: 2,
                },
                // Default: When window width is > 767px, show 4 slides
                // You can adjust this breakpoint based on your design requirements
                768: {
                  slidesPerView: 4,
                },
              }}
            >
            {tourPackages.map((hpackage, index) => (
              <SwiperSlide>
                <div className="swiper-slide swiper-slide-visible swiper-slide-active" key={hpackage.id} 
                style={{marginRight: '30px'}}>
                  <a href={`/${domainName === 'shirdisaitemple' ? 'shirdi-article' : 'article'}/id/${hpackage.id}/${hpackage.title.toLowerCase().replace(/\s+/g, '-')}`} className="citiesCard -type-1 d-block rounded-4 ">
                    <div className="citiesCard__image ratio ratio-4:3">
                      {galleryImages[index] && (
                        <img src={`https://blessingsonthenet.com/img/gallery/${galleryImages[index].thumb}`} alt={hpackage.title} />
                      )}
                      {/*
                      <img
                        src={hpackage.thumb_new ? `https://api.blessingsonthenet.com/uploads/${hpackage.thumb_new.replace(/ /g, '%20')}` : `https://www.blessingsonthenet.com/img/uploads/tour/thumbs/${hpackage.thumb}`}
                        data-src={hpackage.thumb_new ? `https://api.blessingsonthenet.com/uploads/${hpackage.thumb_new.replace(/ /g, '%20')}` : `https://www.blessingsonthenet.com/img/uploads/tour/thumbs/${hpackage.thumb}`}
                        alt="image"
                      />
                      */}
                    </div>

                    <div className="citiesCard__content d-flex flex-column justify-between text-center pt-30 pb-20 px-20">
                      <div className="citiesCard__bg"></div>

                      {/*
                      <div className="citiesCard__top">
                        <div className="text-14 text-white">{hpackage.nights} Night {hpackage.days} Days</div>
                      </div>
                      */}

                      <div className="citiesCard__bottom">
                        <h4 className="text-16 md:text-20 lh-13 text-white mb-10">{hpackage.title}</h4>
                        {/*<p className="starts_from">Starts from ₹{hpackage.price}/-</p>*/}
                        <button className="button col-12 h-40 -blue-1 bg-white text-dark-1">View Details</button>
                      </div>
                    </div>
                  </a>

                </div>
              </SwiperSlide>
            ))}
            </Swiper>
          </div>

          <div className="row pt-20 d-none md:d-block">
            <div className="col-auto">
              <div className="d-inline-block">

                <a href="https://www.blessingsonthenet.com/travels/travel/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                  View All Holidays <div className="icon-arrow-top-right ml-15"></div>
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    {/* Articles Section */}
    <section class="layout-pt-lg layout-pb-md relative" id="secondSection">
      <div data-anim-wrap="" class="container animated">
        <div data-anim-child="slide-up delay-1" class="row y-gap-20 justify-center text-center is-in-view">
          <div class="col-auto">
            <div class="sectionTitle -md">
              <h2 class="sectionTitle__title">Articles</h2>
              {/*<p class=" sectionTitle__text mt-5 sm:mt-0">Here are list of nearby places that you might be intersted in</p>*/}
            </div>
          </div>
        </div>

        <div class="row x-gap-10 y-gap-10 pt-40 sm:pt-20">

          {articleListings.map((temple) => (
            <div key={temple.id} data-anim-child="slide-up delay-4" class="col-xl-4 col-lg-3 col-6 is-in-view">
              <a href={`/${domainName === 'shirdisaitemple' ? 'shirdi-article' : 'article'}/id/${temple.id}/${temple.title.toLowerCase().replace(/\s+/g, '-')}`} class="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4">
                <div class="ml-10 sm:ml-0 sm:mt-10">
                  <h4 title={temple.title} class="text-16 fw-500"> {temple.title.length > 40 ? `${temple.title.substring(0, 40)}...` : temple.title}</h4>
                </div>
              </a>
            </div>
          ))}

        </div>
      </div>
    </section>

    <section className="layout-pt-md layout-pb-md bg-dark-2">
      <div className="container">
        <div className="row y-gap-30 justify-between items-center">
          <div className="col-auto">
            <div className="row y-gap-20  flex-wrap items-center">
              <div className="col-auto">
                <div className="icon-newsletter text-60 sm:text-40 text-white"></div>
              </div>

              <div className="col-auto">
                <h4 className="text-26 text-white fw-600">Your Travel Journey Starts Here</h4>
                <div className="text-white">Sign up and we'll send the best deals to you</div>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="single-field -w-410 d-flex x-gap-10 y-gap-20">
              <form onSubmit={handleSubscriptionSubmit} style={{display:'flex'}}>
                <div>
                  <input
                    className="bg-white h-60"
                    type="email"
                    name="subscriber_email"
                    required
                    placeholder="Your Email"
                    onChange={(e) => setEmail({ email: e.target.value })}
                  />
                  <input style={{display:'none'}} type="text" name="subscriber_user" />
                </div>

                <div style={{marginLeft:'10px'}}>
                <button
                  name="subscription_button"
                  className="button -md h-60 bg-blue-1 text-white"
                  type="submit"
                >
                  Subscribe
                </button>
                </div>
              </form>
            </div>
            {isFormSubmitted && <p>Thanks for subscribing</p>}
          </div>
        </div>
      </div>
    </section>

    </>
  );
}

export default Article;